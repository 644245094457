<template>
	<div class="index">

		<!-- 大图 -->
		<div class="img">
			<img src="../assets/main/img.png">
			<div class="text">
				<div class="textbox container">
					 
					 
					<img src="../assets/main/imgtitle.png" alt="" style="width:300px;margin-left: 1px;"/> 
					 
					<div class="imgbox">
						<img src="../assets/main/lqtyk.png"/>
						<div>
							<input type="text" placeholder="请输入手机号">
						</div>
						<div>
							<input type="text" placeholder="请输入验证码" style="width:100px">
							<p>点击获取验证码</p>
						</div>
						<img src="../assets/main/ljyy.png" style="width:220px;margin-top:20px"/>
					</div>
				</div>
				
			</div>
		</div>
		<!-- 专业教学研发团队 -->
		<div :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox">
		 
				<img src="../assets/index2/img1.png" />				 
			 <!-- <div class="container">
				<div class="yanfa">
					<div class="yfleft">
						<img src="../assets/main/ewm.png" alt="">
					</div>
					<div class="yfright">
						<img src="" alt="">
						<p><strong>樊振东</strong></p>
						<p>乒乓球技术总监</p>
						<p>中国乒乓球运动员，世界冠军，奥运冠军，曾任中国乒乓球队总教练，现任国际乒乓球联合会执行副主席</p>
					</div>
				</div>

			 </div> -->
		

		</div>	
		
		<!-- 丰富多样的课堂形式 -->
		<div :class="Animate3 ? 'servebox myanimation' : 'servebox'" ref="servebox">			
			<img src="../assets/index2/img2.png" class="imgtitle"/>	
			<div class="serve container">
				<img src="../assets/main/EVEN0007.png"/>	
				<div>
					<p><span></span>浓厚训练氛围，因材施教，给予孩子全方位关注 </p>
					<p><span></span>小班授课，开放训练，家长随时旁听  </p>
					<p><span></span>训、赛、测结合，高效直播运动课，家长更安心 </p>
					
					
				</div>
			</div> 			
			 
		</div>		
		<!-- 我们的教学目标 -->
		<div :class="Animate4 ? 'logobox myanimation' : 'logobox'" ref="worksbox">
			<img src="../assets/index2/img33.png" class="imgtitle"/>	
			 
		</div>	
		<!-- 泖冠体育简介 -->
		<div :class="Animate5 ? 'advertisementbox myanimation' : 'advertisementbox'" ref="advertisementbox">
			<img src="../assets/index2/img4.png" class="imgtitle"/>	
			<div class="viewlist container">
				 
				<div style="width:80%;margin:20px auto">
					<img src="../assets/main/EVEN0007.png" alt="">
				</div>
				<p>
					乒乓球起源于英国，“乒乓球”一名起源自1900年，因其打击时发出“Ping Pong”的声音而得名。
					在中国大陆以“乒乓球”作为它的官方名称，中国香港及澳门等地区亦同。1926年1月，在德国柏林举行了一次国际乒乓球赛，
					共有9个国家的64名男运动员参加了比赛。同年12月，国际乒乓球联合会正式成立，并把在伦敦举行的欧洲锦标赛命名为第一
					届世界乒乓球锦标赛。
				</p> 
			</div>
		</div>
		<!-- 展览展示作品集 -->
		<div :class="Animate6 ? 'viewbox myanimation' : 'viewbox'" ref="viewbox">
			<img src="../assets/index2/img5.png" class="imgtitle"/>	
			<div class="viewlist container">
			 
				<el-row :gutter="24">
					<el-col  :sx="12" :sm="8" :lg="8"  class="col">
						 <div  class="view">
							<img src="../assets/main/ryzs1.png" alt="">							 		 
						</div>
					</el-col>	
					<el-col  :sx="12" :sm="8" :lg="8"  class="col">
						 <div  class="view">
							<img src="../assets/main/ryzs2.png" alt="">							 		 
						</div>
					</el-col>
					<el-col  :sx="12" :sm="8" :lg="8"  class="col">
						 <div  class="view">
							<img src="../assets/main/ryzs3.png" alt="">							 		 
						</div>
					</el-col>
				</el-row>
				
				 
			</div>
		</div>
		 
		<div class="ewmbox">
			<div class="wx">
				<img src="../assets/main/wx.png" alt="">
				<p>微信</p>
				<img src="../assets/main/ewm.png" alt="" class="posewm"/>
			</div>
			<div class="xcx">
				<img src="../assets/main/xcx.png" alt="">
				<p>小程序</p>
				<img src="../assets/main/ewm.png" alt="" class="posewm"/>
			</div>
			
		</div>
		 
	</div>
</template>

<script>
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'
import arrowAnima from '@/components/arrowAnima.vue'
import { isElementNotInViewport } from "@/utils/utils";
import btnAnima from '../components/btnAnima.vue'
import ArrowAnima from '../components/arrowAnima.vue'
export default {

  components: {
    myheader,
    myfooter,
    arrowAnima,
    btnAnima,
    ArrowAnima
},

  data () {
    return {
		Animate1: false,
		Animate2: false,
		Animate3: false,
		Animate4: false,
		Animate5:false,
		Animate6:false,
		Animate7:false,
		Animate8:false,
		servelist:[
			{title:"影视动画制作",img:"../assets/other/serve1.png",text:"影片设计、脚本设计、影片拍摄、动画制作、三维特效等……"}
		],
		silderList:[{},{}],
		sindex:0,
		workslist:[{},{}],
		windex:0
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll',this.scrollToTop);
    next();
  },
  methods: {
	// 滚动事件
    scrollToTop() {
	  this.Animate1=!isElementNotInViewport(this.$refs.logobox)
	//   this.Animate2=!isElementNotInViewport(this.$refs.videobox)
	  this.Animate3=!isElementNotInViewport(this.$refs.servebox)
	  this.Animate4=!isElementNotInViewport(this.$refs.worksbox)
	  this.Animate5=!isElementNotInViewport(this.$refs.advertisementbox)
	  this.Animate6=!isElementNotInViewport(this.$refs.viewbox)
	  this.Animate7=!isElementNotInViewport(this.$refs.advantagebox)
	   
    },
	scrollEvent(){
		console.log(12466666)
	},
	next(n){
		if(n==0){
			this.sindex+=1
		}else if(n==1){
			this.windex+=1
		}
		
	},
	previous(n){
		if(n==0){
			this.sindex+=-1
		}else if(n==1){
			this.windex+=-1
		}
	}
  }
}
</script>

<style lang="scss" scoped>
$hoverbg:#00A1E8;
$color:#00A1E8;
.ewmbox{
	position: fixed;
	top: 200px;
	right: 40px;
	background-color: rgb(248, 248, 248);
	width: 60px;
	// height: 180px;
	border-radius: 40px;
	padding: 20px 0; 
	>div{
		// position: relative;
		text-align: center;
		font-size: 12px;
		margin: 10px 0;
		color: #333333;
		img{
			width: 40px;
		}
		.posewm{
			position: fixed;
			right: 100px;
			top: 250px;
			width: 100px;
			opacity: 0;
			display: none;
			transition:display 1s;
		}
	}
	.xcx:hover .posewm{
		display: block;
		opacity: 1;
	}
	.wx:hover .posewm{
		display: block;
		opacity: 1;
	}
}
.mybtn{
	width: 300px;
	margin: 50px auto;
}
.imgtitle{
	width: 100%;
}
.silder{
	 
	display: flex;
	align-content: space-between;
	align-items: center;
	.silder-item{
		padding: 40px;
		flex: 1;
	}
	>div>img{
		width: 34px;
		height: 50px;
	}

	.right-icon{
		
	}
}

.myanimation{
 
  animation: load 0.51s linear;
  transform:translateY(0px);
  opacity: 1;
}
@keyframes load {
	0% {
		opacity: 0;
    // top: -20px;
    transform:translateY(80px)
	}
	100% {
		opacity: 1;
    // top: 20px;
    transform:translateY(0px)
	}
}
.index{
	width: 100%;
	 .img{
		position: relative;
		// top: 0;
		width: 100%;
		// min-height: 600px;
		text-align: center;
		color: #fff;
		img{
			width: 100%;
		}
		.text{
			position: absolute;
			top: 20px;
			left: 10px;
			width: calc(100% - 20px);
			.textbox{				 
				 text-align: left;
				 
				.imgbox{
					width: 258px;
					height: 251px;
					background: #ffffff;
					border-radius: 18px;
					margin-top:10px; 
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 20px;
					img{
						width: 100px;
					}
					>div{
						width: 220px;
						height: 32px;
						background: #f6f6f6;
						border-radius: 4px;
						margin-top: 30px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 10px;
						input{
							flex: 1;
							background: #f6f6f6;
						}
						p{
							 
							width: 150px;
							color: $color;
							font-size: 12px;
							cursor: pointer;
							text-align: right;
						}
					}
				}

			}

		}
	 }
	 .logobox{
		width: 100%;
		// min-width: 1200px;
		background-color: #fff;
		padding:20px 50px;
		
		>img{
			width: 100%;
		}
		.yanfa{
			display: flex;
			justify-content: center;
			.yfright{
				width: 300px;
			}
		}
	 }
	 
	 .servebox{
		// padding: 20px 0;
		width: 100%;
		background: #F3F4F6 url('../assets/main/bg.png') 0 0 no-repeat;
		padding: 1px;
		 
		.serve{
			 display: flex;
			 justify-content: center;
			 align-items: center;
			 img{
				width: 450px;
			 }
			 div{
				margin-left: 20px;
				line-height: 40px;
				p{
					display: flex;
					align-items: center;
					font-size: 14px;
					span{
						display: block;
						width: 9px;
						height: 9px;
						background-color:#ff9240 ;
						border-radius: 5px;
						margin-right: 10px;
					}
				}
			 }
			 
		}
		
	 }
	 .worksbox{
		width: 100%;
		min-width: 1200px;
		background-color: #F3F4F6;
		padding:30px 50px;
		h2{
			text-align: center;
			color: #323232;
			font-size: 32px;
		}
		h3{
			text-align: center;
			margin: 30px 0;
		}
		.works{
			background-color: #fff;
			padding: 10px;
			border-radius: 10px;
			margin-top: 20px;
			 
			
			p{
				color: #323232;
				line-height: 30px;
			}
			.works-img{
				width: 100%;
				height: 125px;
				position: relative;
				overflow: hidden;
				 
					
				.works_item_img{
					width: 100%;
					// height: 100%;
					transition: 0.8s;
					margin: 0;
					padding: 0;
					object-fit: fill;
					border-radius: 5px;
				}
				.works_item_icon{
					background: rgba(0,0,0,0.5);
					height: 100%;
					left: 0;
					opacity: 0;
					position: absolute;
					top: 0;
					transition: .3s;
					// visibility: hidden;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 5px;
					img{
						width: 60px;
					}
				}

			}
			.works-img:hover .works_item_img{
				transform: scale(1.03);
			}
			.works-img:hover .works_item_icon{
				opacity: 1;
			}

		}
	 }
	 .advertisementbox{
		width: 100%;
		min-width: 1200px;
		 
		padding:30px 50px;
		h2{
			text-align: center;
			color: #323232;
			font-size: 32px;
		}
		h3{
			text-align: center;
			margin: 30px 0;
		}
		
		img{
			width: 100%;
			height: auto;
		}

	 }
	 .viewbox{
		width: 100%;
		min-width: 1200px;		 
		padding:30px 50px;
		 
		h2{
			text-align: center;
			color: #323232;
			font-size: 32px;
		}
		h3{
			text-align: center;
			margin: 30px 0;
		}
		.view{
			position: relative;
			margin: 40px 0px;
			border-radius: 5px;
			cursor: pointer;
			.view-title{
				position: absolute;
				bottom: -50px;
				left: 0;
				width: 100%;
				background-color: #2F2F2F;
				color: #fff;
				border-radius:0 0 5px 5px;
				line-height: 60px;
				text-align: center;
			}

			img{
				width: 100%;
			}
			.view-bg {
				opacity: 0;
				position: absolute;
				top: 43%;
				left: 45%;
				width: 13%;
				height: 10%;
				background-color: #fff;
				
			}
			.view-works{
				position: absolute;
				top: 10%;
				left: 0;
				width: 100%; 
				opacity: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				h4{
					text-align: center;
					line-height: 40px;
					font-size: 18px;
				}
				p{
					width: 150px;
					background-color: #cc8a18;
					line-height: 30px;
					border-radius:3px;
					color: #fff;
					margin-top: 20px;
					cursor: pointer;
				}
			}

		}
		
		.col:nth-child(even){
			.view-title{
				background-color: #646464;
			}
		
		} 
		.view:hover .view-bg {
			display: block;
			transform-origin: 50% 50%;
			animation-name: myfadein;
			animation-duration: 500ms;
			animation-fill-mode: both;
		}
		.view:hover .view-works {
			opacity: 0;
			font-size: 12px;
			text-align: center;
			width: 100%;
			padding: 20px 0;
			animation-name: myfadein1;
			animation-duration: 600ms;
			animation-fill-mode: both;
			animation-delay: 300ms;
		}
		
		@keyframes myfadein{
			0% {
				opacity: 0;
				transform: scale(0);
			}
			100% {
				opacity: 0.8;
				transform: scale(8);
			}
		}
		@keyframes myfadein1{
			0% {
				opacity: 0;
				// transform: translateY(-20px);
			}
			100% {
				opacity: 1;
				// transform: translateY(7px);
			}
		}

	 }
	 .advantagebox{
		.container>h3{
			text-align: center;
			margin: 30px 0;
			font-size: 32px;
		}
		.advantage{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			width: 500px;
			margin-top: 60px;
			 h3,p{
				text-align: left;
				margin: 15px 0;
				line-height: 26px;
			}
			 
		}
		.col{
			display: flex;
			flex-direction: column;
			align-items: center;
			
			img{
				width: 400px;
				
			}
		}
		.ys{
			transition: 0.8s;
		}
		.ys:hover{
				transform: scale(1.03);
		}
	 }
	  

}
.v-enter,.v-leave-to{
    opacity: 0;
    transform: translateX(-300PX);
    
}

.v-enter-active,.v-leave-active{
    transition: all 0.6s ease;
}
</style>
