<template>
     
      <div class="btnAnima"  >
        
         
        <p>{{title}}</p>
         
        <img class="icon_arrow" src="@/assets/public/right-icon.png" alt="icon_arrow">
        
      </div>
   
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: '详细信息'
    },    
    fontSize:{
      type: String,
      default: '14px'
    },
    whitecolor:{
      type: Boolean,
      default: true
    },
    width:{
      type: String,
      default: '30px'
    }
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    onClick(){
      this.$emit("onClick")
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
  $hoverbg:#cc8a18;
.btnAnima{
  cursor: pointer;
  width: 100%;
  background-color: #DC9A28;
  height: 60px;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
  box-shadow: 0px 6px 12px 0px rgb(144, 137, 125);
  >p{
    color: #fff;
    line-height: 60px;
    text-align: center;
  }
  .icon_arrow{
    width: 40px;
    display: block;
   
    position: absolute;
    right: 2em;
    top: 50%;
    transform: translatey(-50%);
  }
}
  .btnAnima:hover {
    background-color: $hoverbg;
  }
  .btnAnima:hover .icon_arrow {
    animation: arrow_btn 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  @keyframes arrow_btn{
    0% {
        opacity: 1;
        transform: translate(0,-50%);
    }
    5% {
        opacity: 1;
        transform: translate(0,-50%);
    }
    35% {
        opacity: 0;
        transform: translate(100%,-50%);
    }
    40% {
        transform: translate(-100%,-50%);
    }
    45% {
        opacity: 0;
        transform: translate(-100%,-50%);
    }
    75% {
        opacity: 1;
        transform: translate(0,-50%);
    }
    100% {
        opacity: 1;
        transform: translate(0,-50%);
    }
  }

</style>
