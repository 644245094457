<template>
     
      <div class="arrowAnima flex" @click="onClick">
        <div class="flex icon_circle_arrow" 
        :style="{'width':width,'height':width}">
          <img class="icon_arrow" 
          src="@/assets/public/right-icon.png" alt="icon_arrow">
        </div>
        <div class="flex">
          <p :class="whitecolor ? 'line_anima' : 'line_anima line_anima2'" :style="{fontSize}">{{title}}</p>
        </div>
      </div>
   
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: '详细信息'
    },    
    fontSize:{
      type: String,
      default: '14px'
    },
    whitecolor:{
      type: Boolean,
      default: true
    },
    width:{
      type: String,
      default: '30px'
    }
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    onClick(){
      this.$emit("onClick")
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.arrowAnima{
  cursor: pointer;
  .icon_circle_arrow {
      background: #DC9A28;
      border-radius: 99em;
      box-shadow: 0px 6px 12px 0 rgba(218, 153, 40, 0.4);     
      margin-right: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
      .icon_arrow{
        width: 70%;
        min-width: 20px;
      }
  }
  .line_anima{
    position: relative;
    color: #fff;
  }
  .line_anima2{
    color: #000;
  }
}
.arrowAnima:hover .line_anima{
	color: #DC9A28;
}
.arrowAnima:hover .line_anima::after {
	opacity: 1;
	transform: scalex(1);
}
.line_anima::after{
	background: #DC9A28;
	bottom: -5px;
  content: '';
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  transition: transform .4s;
  transform: scalex(0);
  transform-origin: left;
  width: 100%;
}
.arrowAnima:hover .icon_arrow {
    -webkit-animation: arrow 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation: arrow 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation: arrow 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    animation: arrow 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@keyframes arrow{
  0% {
      opacity: 1;
      transform: translate(0,0);
  }
  5% {
      opacity: 1;
      transform: translate(0,0);
  }
  35% {
      opacity: 0;
      transform: translate(100%,0);
  }
  40% {
      transform: translate(-100%,0);
  }
  45% {
      opacity: 0;
      transform: translate(-100%,0);
  }
  75% {
      opacity: 1;
      transform: translate(0,0);
  }

  100% {
      opacity: 1;
      transform: translate(0,0);
  }
}
</style>
